! function(o, c) {
	var n = c.documentElement,	t = " w-mod-";
	n.className += t + "js", ("ontouchstart" in o || o.DocumentTouch && c instanceof DocumentTouch) && (n.className += t + "touch")
}(window, document);

$(document).ready(function($) {
	// Mobile menu
	var $mobileMenuTrigger = $(".w-nav-button");
	var $mobileMenu = $(".mobile-navigation-menu");

	$mobileMenu.mmenu({
		extensions: ["position-right"],
		navbar: {
			add: true,
			title: ""
		},
		screenReader: {
			aria: true,
			text: true
		}
	});

	var API = $mobileMenu.data("mmenu");

	$mobileMenuTrigger.click(function() {
		API.open();
		$mobileMenuTrigger.addClass("w--open");

		return false;
	});

	$mobileMenu.data('mmenu').bind('closed', function() {
		$mobileMenuTrigger.removeClass("w--open");
	});

	// Add dropdown class to main nav items
	$(".nav-drop-wrap.w-dropdown:has('.nav-drop-list.w-dropdown-list')").addClass("has-dropdown");

	// Main nav drop-down menus
	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".has-dropdown > .nav-drop-toggle-link").click(function(event) {
			if ( !$(this).parents(".has-dropdown").hasClass("open") ) {
				$(".nav-drop-wrap.w-dropdown").removeClass("open");
				event.preventDefault();
				$(this).parent(".has-dropdown").addClass("open");
			}
		});
	}
	else {
		$(".nav-drop-wrap.w-dropdown").hover(function() {
			$(this).addClass("open");
		}, function() {
			$(this).removeClass("open");
		});
	}

	// You can add this class to the nav element for convinience
	// Accessible menu
	$(".desktop-navigation-menu").accessibleMenu();

	// Remove image link border bottom
	$('a img').parent('a').addClass('image-link');

	//*** Apply FitVids to YouTube videos ***//
	if ($("body").innerWidth() <= 767 ) {
		$(".section-wrap.inside-content-section").fitVids({ ignore: ".nofit" });
	}
})
